body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navBar{
  background-color: #203344;
  opacity: 0.95;
}

.navBar .navIcon{
  height: 40px;
}

.navbar-dark .navbar-brand {
  color: #d98695!important;
}

.navbar-nav .nav-link {
  color: #d98695 !important;
  text-decoration: none;
}

.home{
  background-image: url("images/family.jpg");
  height: 100%;
  width: 100%;
  background-position: 35%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}


.home .textField {
  text-align: center;
  position: absolute;
  top: 47vh;
  font: 100 1.2vmin/2.5vh cookie;
}


.home .rsvpCodeInput {
  text-align: center;
  position: absolute;
  top: 53vh;
  font: 100 1.2vmin/2.5vh cookie;
}

.home button{
  background-color: #d98695 !important;
}

.root{
  flex-grow: 1;
  height: 100%,;
  width: 100%;
}

.banner {
  background-repeat: no-repeat;
  background-position :center;
  background-size: cover;
  height: 50vh;
  width: "100%";
}

.heading{
  padding: 30px;
}

.faq .body{
  position: absolute;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

acordion{
  margin-top: 10px;
}

.rsvp{
  position: absolute;
  top: 50%;
  width: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  transform: translateY(-50%);
  margin-right: auto;
}

.rsvpForm {
  position: absolute;
  align-items: center;
  background-color: rgba(255,255,255,0.95);
  width: 65% !important;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 60%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rsvp .textField{
  background-color: rgba(255,255,255,0.95);
  width: 100%;
}

.rsvp .textField .rsvpInputText{
  color: #203344;
  border-bottom: #d98695;
  height: 45px;
}

.rsvpForm > div {
  padding: 20px;
  height: 100%;
}

.rsvpForm  .rsvpInputText{
  color: #203344;
  border-bottom: #d98695;
  height: 45px;
  padding: 20px;
}

.rsvpForm .actionButton {
  background-color: #203344;
  opacity: 0.88;
  color: #d98695;
  text-align: right;
  margin: 5px;
}
.rsvpForm .actionReturnButton {
  text-align: right;
  margin: 5px;
}

.rsvp .actionButton {
  text-align: center;
  margin: 5px;
}

.fullSizedField {
  width: 100%;
}

.rsvpDivider {
  width: 100%;
  margin-top: 20px !important
}

.timeline{
  padding-top: 5%;
  width: 75%;
  padding-left: 25%;
}

@media (max-width: 600px)  {
  .timeline{
    width: 100%;
    padding-left: 0%;
  }
}

@media (max-width: 450px)  {
  .adminTitle{
    font-size: 1.2rem !important;
  }
}

@media (max-width: 300px)  {
  .adminTitle{
    font-size: 1rem !important;
  }
}

.adminContainer{
  width: 100%;
  height: 100%;
  margin-top: 10vh;
  padding: 0% 5%;
}

.adminBody{
  padding-top: 4%;
  width: 100%;
  height: 100%;
}

.tableCell{
  width: 40%;
}
